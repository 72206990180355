
$(document).on('turbolinks:load', function()
{

  function selected()
  {
    return $("#user_role_ids"+ " option").filter(":selected").text();
  }
  
  function Role_Type()
  {
    return 'Account Portfolio Manager'
  }

  if (selected() === Role_Type()) 
  {
    $(".user-complex-checkboxes").show();
  }
  else
  {
    $(".user-complex-checkboxes").hide();
  }

  $(document).on('change', function() 
  {
    if (selected() === Role_Type()) 
    {
      $(".user-complex-checkboxes").slideDown();
    }
    else
    {
      $(".user-complex-checkboxes").slideUp();
    }
  });
});



