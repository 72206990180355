// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@popperjs/core");
import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import Chartkick from "chartkick";
import Chart from "chart.js";

import "bootstrap";
import "jquery";
import "jquery.easing/jquery.easing";
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";
import "simple-datatables";

import "./shared/datatables/datatables-load";
import "./shared/sb-admin-2-pro";
import "./shared/tooltips";
import "./shared/toasts";
import "./shared/litepicker";
// import "./shared/markdown";
import "./shared/users";
import "./shared/clickable-row";
import "./shared/generate-invoice-modal";
import "./shared/issues";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Chartkick.use(Chart);

// This allows the js.erb files to update the view
window.jQuery = $;
window.$ = $;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// const imagePath = (name) => images(name, true)
