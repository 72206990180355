// Make rows in tables clickable with given href
$(document).on("turbolinks:load", function () {
  $(".report_issue").on("click", function (e) {
    e.preventDefault();
    var issue_id = $(this).attr("data-issue-id");
    var issue_type = $(this).attr("data-issue-type");
    $("form").attr("action", "/issues/" + issue_id);
    $("#issue_type").val(issue_type);
    $("#reportIssueModal").modal("show");
  });

  $("#report_issue_btn").attr("disabled", "disabled");
  $("#reported_to_user_id").on("change", function () {
    if (this.value != "") {
      $("#report_issue_btn").removeAttr("disabled");
    } else {
      $("#report_issue_btn").attr("disabled", "disabled");
    }
  });
});
