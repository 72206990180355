// Simple-DataTables
// https://github.com/fiduswriter/Simple-DataTables/wiki

$(document).on("turbolinks:load", function () {
  loadDataTable("licensesTable");
  loadDataTable("complexTable");
  loadDataTable("invoicesHistoryTable");
  loadDataTable("invoicesHistoryTable");
  loadDataTable("SuppliersTable");
  loadDataTable("SupplierInvoicesTable");
});

function loadDataTable(tableId, options = {}) {
  const table = document.getElementById(tableId);
  if (table) {
    new DataTable(table, options);
  }
}
